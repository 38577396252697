import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import AakdEvent from "../../components/event/aakd";

const About = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | AAKD">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="AAKD US Open NYC" crumbTitle="AAKD US Open NYC" />
      <AakdEvent showbutton top />
    </div>
    <Footer />
  </Layout>
);

export default About;
