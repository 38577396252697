// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import { Link } from "@gatsbyjs/reach-router";

const AosEvent = ({ showbutton, top }) => {
  return (
    <section className={`${top && "pt-120"} pb-120`}>
      <Container>
        <Row className="pb-40">
          <Col lg={10}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  August 23d-26th, 2024
                </p>
                <h3>AAKD US Open NYC</h3>
              </div>
              <p>
                As part of our mission to provide holistic tennis, MYLT is
                excited to launch our annual trip to the Arthur Ashe Kids’ Day
                at the US Open in New York. This four-day trip, from August 23rd
                to August 26th, is an excellent opportunity for participants to
                watch professional tennis matches, attend New York concerts and
                tours, meet professional tennis players, and participate in
                interactive games and family activities. Be a part of this
                enriching experience!
              </p>
              <p>Trip Includes</p>
              <ul>
                <li>Round-trip bus transportation</li>
                <li>Accommodations</li>
                <li>Event Tickets to US Open</li>
                <li>Sightseeing Tours</li>
              </ul>
              <p>Payment Details</p>
              <ul>
                <li>Deposit/per person : $250 first payment due July 5</li>
                <li>Deposit/per person: $250 final payment due July 15</li>
                <li>Trip/per person: $500 paid in full</li>
              </ul>
              <p>Spaces are limited, so take advantage of this! Secure your spot.</p>
            </div>
          </Col>
        </Row>
        {/* {showbutton && (
          <a href="https://google.com" target="_blank">
            <button className="thm-btn dynamic-radius">Buy Tickets</button>
          </a>
        )} */}
           <Link className="thm-btn dynamic-radius" to="/contact">
            Contact Us for Tickets
          </Link>
      </Container>
    </section>
  );
};

export default AosEvent;
